.datepicker,
.timepicker {
    &-toggle-button {
        color: $gray;

        &:hover,
        &:focus {
            color: $secondary-dark !important;
        }
    }

    &-wrapper {
        position: relative;
    }

    &-clear-button {
        position: absolute;
        right: 25px;
        top: 52%;
        transform: translateY(-50%);
        border: none;
        color: $gray;
        background-color: $gray-light;

        &:hover{
            color: $secondary-dark;
        }
    }
}