.angular-editor-textarea {
    background-color: $gray-light !important;
    outline: none;
    font-size: 14px;
    margin-top: 0 !important;
    border: none !important;
  
    &::after {
      display: none !important;
    }
  }
  
  .angular-editor-placeholder {
    font-size: 14px;
  }
  
  .angular-editor-toolbar {
    border: none !important;
    padding-bottom: 0 !important;
    border-radius: 2px 2px 0 0;
  }
  
  .angular-editor-button {
    border: none !important;
    background: none !important;
    color: $secondary !important;
  
    &.active {
      color: $primary !important;
    }
  }
  
  .angular-editor {
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(lighten($secondary-light, 10%)),
        color-stop(99%, darken($secondary-light, 10%)),
        to(darken($secondary-light, 10%))
      );
      background-image: -webkit-linear-gradient(
        bottom,
        lighten($secondary-light, 10%) 0%,
        darken($secondary-light, 10%) 99%,
        darken($secondary-light, 10%) 100%
      );
      background-image: linear-gradient(
        to top,
        lighten($secondary-light, 10%) 0%,
        darken($secondary-light, 10%) 99%,
        darken($secondary-light, 10%) 100%
      );
    }
  
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 10px;
    }
  }