// avatar
.avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    overflow: hidden;

    &-lg {
        width: 71.63px !important;
        height: 70px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    img {
        vertical-align: top;
    }

    &-md {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
    }

    &-xl {
        width: 120px;
        height: 120px;
    }

    &-lg {
        width: 70px;
        height: 70px;
        line-height: 65px;
        text-align: center;
        overflow: hidden;
        position: relative;
        // border: 5px solid $light;

        i {
            color: $primary;
            font-size: 1.5rem;
        }
    }
}

.separator {
    display: inline-block;
    text-align: center;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray-light;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.bg-image {
    height: 5rem;
}

.fa-ul {
    margin-left: 1.25rem !important;
}

nav {
    ol>li {
        &.breadcrumb-item {
            font-size: large;
        }

        a.active {
            color: $primary !important;
        }
    }
}

.note {
    &-body {
        height: calc(100vh - 270px);
    }

    &-footer {
        box-shadow: 0 4px 12px #00000012, 0 2px 4px #0000000d;
        background: $white;
        height: 225px;
    }
}

.map-legends {
    .fa-circle, 
    .fa-path {
        color: #3cb371;
    }
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      20: 0.2,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
        ))
);

.blurred {
    filter: blur(2px);
  -webkit-filter: blur(2px);
 
  & button {
    pointer-events: none;
  }
}

.warning-badge {
    border: 1px solid $warning;
    background-color: $white;
    padding: 6px;
    border-radius: 6px;

    & i {
        margin: 2px 6px 0 0;
    }
}
