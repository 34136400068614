.btn {
    text-transform: unset;
    height: $btn-height;
    box-shadow: none;
    font-weight: $font-weight-normal;
    &-sm{
      height: $btn-height-sm;
    }
    &-floating {
      &.dropdown-toggle {
        &:after {
          display: none;
        }
      }
    }
    &-lg {
      height: $btn-height-lg;
    }
    &-link {
      &:hover {
        box-shadow: none !important;
    }
    }
    &:disabled,
    &.disabled,
    fieldset:disabled & {
      box-shadow: none;
    }

    &-transparent{
      border: none;
      background: none;
    }
  }
  .navbar-toggler {
    position: absolute;
    // z-index: $zindex-popover;
    // left: -15px;
    top: 24px;
    &:hover{
      color: $primary;
    }
  }
  
  a {
    cursor: pointer;
  }
  