.badge {
    &-notification {
        background-color: $danger;
        color: $white;
        border-radius: 50%;
        min-width: 20px;
        width: auto;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        border: 1px solid $white;
        font-size: 10px;
        padding-top: 2px;
    }
}