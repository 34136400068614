.table-responsive{
    max-height: calc(100vh - 186px);
    max-width: calc(100vw - 106px);
}
.table th {
    font-weight: 500 !important;
}

.table td {
    font-weight: 400;
    white-space: nowrap;
}

.bi-three-dots-vertical {
    color: $gray !important;
}