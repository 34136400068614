.timepicker-head {
    background-color: $white;
    overflow: hidden;
    height: 75px;
}

.timepicker-head-content {
    width: 220px  !important;
}

.timepicker-hour, .timepicker-minute, .timepicker-dot, .timepicker-submit {
    color: $secondary-dark;
    font-size: 30px;

    &.active {
        color: $secondary-dark;
    }
}

.timepicker-hour-mode {
    margin-left: 0 !important;
    color: $secondary-dark;
    font-size: 14px;
    margin-right: 8px !important;

    &.active {
        background-color: $white;
        color: $secondary-dark;
    }
}

.timepicker-elements {
    min-width: 226px !important;
}

.timepicker-container {
    border-radius: 8px;
}

.timepicker-icon-up {
    color: $secondary-dark !important;
    top: -16px;
}

.timepicker-icon-down {
    color: $secondary-dark !important;
    bottom: -38px;
}

.timepicker-submit-inline {
    height: 55px;
    margin-left: 0;
    font-size: 16px;
}

.timepicker-am, .timepicker-pm {
    &:hover {
        background-color: $white;
    }
}