.accordion {
    &-item{
        border: none;
    }

    &-button{
        background-color: $gray-light !important;
        border: none;

        &:not(.collapsed){
            box-shadow: none !important;
        }
    }
}