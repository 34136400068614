
.modal-header{
    border: 0;
    padding: 1rem !important;
}

.modal-dialog {
    max-height: 85vh;
}

.modal-body {
    padding: 1rem !important;
}

.modal-footer {
    padding: 1rem !important;

    > button { 
        margin: 0;
    }
}